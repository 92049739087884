"use client";
import Modal from "@/components/Modal/Modal";
import { useState, useTransition } from "react";
import { Planet } from "@/icons/planet";
import { locales } from "@/config";
import { useRouter, usePathname } from "@/navigation";
import { useLocale, useTranslations } from "next-intl";
import { clsx } from "clsx";
import { useParams } from "next/navigation";

const langFullName = {
  en: "English",
  es: "Español",
  fr: "Français",
  az: "Azərbaycan",
  ru: "Русский",
  pt: "Português"
};

export const LangToggle = () => {
  const [showModal, setShowModal] = useState(false);
  const locale = useLocale();
  const pathname = usePathname();
  const [isPending, startTransition] = useTransition();
  const t = useTranslations();
  const params = useParams();

  const handleOpen = () => {
    setShowModal(true);
  };
  const router = useRouter();

  // const handleLocaleChange = (newLocale: string) => {
  //   setShowModal(false);
  //   startTransition(() => {
  //     router.replace(
  //       // @ts-expect-error -- TypeScript will validate that only known `params`
  //       // are used in combination with a given `pathname`. Since the two will
  //       // always match for the current route, we can skip runtime checks.
  //       { pathname, params },
  //       { locale: newLocale },
  //     );
  //   });
  // };
  const handleLocaleChange = (value: (typeof locales)[number]) => {
    console.log(pathname, "pathname");
    router.push(pathname, { locale: value });
  };

  return (
    <>
      <div
        onClick={handleOpen}
        className="ml-2 flex cursor-pointer items-center gap-2 border-l-2 pl-2 hover:text-primary "
      >
        <Planet />
        <button
          className={
            isPending ? "transition-opacity [&:disabled]:opacity-30" : ""
          }
        >
          {locale.toUpperCase()}
        </button>
      </div>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <div className="flex items-center justify-between rounded-t border-b pb-4">
          <h3 className="text-2xl font-semibold leading-8 text-primary">
            {t("select_language")}
          </h3>
        </div>

        <div className="flex flex-col gap-4 pt-2">
          {locales.map((locale) => (
            <button key={locale} onClick={() => handleLocaleChange(locale)}>
              {langFullName[locale]}
            </button>
          ))}
        </div>
      </Modal>
    </>
  );
};
