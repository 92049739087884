import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-themes/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/components/error-boundary.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"preload\":false}],\"variableName\":\"poppins\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-hot-toast/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-modal-video/css/modal-video.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/error.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Common/LayoutWrapper.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ProgressBar/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ScrollToTop/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/index.css")