"use client";

import { usePathname } from 'next/navigation';
import Header from "@/components/Header";
import Footer from "@/components/Footer";

interface LayoutWrapperProps {
  session: any;
  children: React.ReactNode;
}

export default function LayoutWrapper({ session, children }: LayoutWrapperProps) {
  const pathname = usePathname();
  const isForgotPasswordPage = pathname.includes('/forgot-password') || pathname.includes('/password-recovery');

  return (
    <>
      {!isForgotPasswordPage && <Header session={session} />}
        {children}
      {!isForgotPasswordPage && <Footer isAbout={pathname.includes('/about')} />}
  </>
);
}