import { Menu } from "@/types/menu";

const menuData: Menu[] = [
  // {
  //   id: 1,
  //   title: "Esims",
  //   path: "/",
  //   newTab: false,
  // },
  // {
  //   id: 2,
  //   title: "about",
  //   path: "/about",
  //   newTab: false,
  // },
  {
    id: 2,
    title: "compatible_devices",
    path: "/compatible-devices",
    newTab: false,
  },
  {
    id: 3,
    title: "sign_in",
    path: "/signin",
    newTab: false,
  },
  {
    id: 4,
    title: "sign_up",
    path: "/signup",
    newTab: false,
    primaryStyle: true,
  },
];
export default menuData;
