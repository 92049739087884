"use client";

import NextTopLoader from "nextjs-toploader";
import { useRouter } from "@/navigation";
import { useEffect } from "react";

const ProgressBar: React.FC = () => {
  return <NextTopLoader showSpinner={false} color="#5bc2e7" />;
};

export default ProgressBar;
