import Image from "next/image";
import Link from "next/link";
import { useTranslations } from "next-intl";

const footerItems = [
  // { title: "what_is_esim", href: "" },
  { title: "about_us", href: "about",slug:"About Us" },
  { title: "help_center", href: "/contact",slug:"Help Center" },
  // { title: "download_app", href: "" },
];
const Footer = ({ isAbout = false} ) => {
  const t = useTranslations();
  return (
    <>
      <footer className="dark:bg-gray-dark relative z-10 bg-white pt-16 md:pt-20 lg:pt-24">
        <div className="container py-12">
          <div className="flex flex-col flex-wrap justify-between gap-8 pb-12 md:flex-row">
            <Image
              src="/images/logo/logo5.svg"
              alt="logo"
              width={200}
              height={100}
              className=" dark:hidden"
            />
            <div className="flex flex-col gap-8 md:flex-row md:items-center">
              {footerItems.map((item) => (
                <div
                  key={item.title}
                  className="flex flex-col gap-y-4 md:gap-y-6"
                >
                  <div className="flex items-center">
                    <Link
                      className="focus-visible:shadow-focus body-md-medium inline-flex items-center align-bottom text-black transition-colors ease-out hover:text-secondary hover:underline focus-visible:outline-none active:text-primary"
                      data-ga-slug={item.slug}
                      href={item.href}
                    >
                      {t(item.title)}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <hr className="border-neutral-900 border-opacity-25" />
          <div className="mt-8">
            <div className="flex flex-col-reverse flex-wrap-reverse items-center justify-between gap-8 md:flex-row">
              <p className="body-xs-medium flex flex-wrap items-start gap-6 font-normal sm:items-center">
                <span className="text-tertiary font-normal">
                  2024 Esimafly.
                  {t("all_rights_reserved")}
                </span>
                <span className="flex flex-wrap items-center gap-6">
                  <Link
                    className="focus-visible:shadow-focus text-tertiary inline-block align-bottom underline transition-colors ease-out hover:text-neutral-700 focus-visible:outline-none"
                    data-ga-slug="Privacy Policy"
                    href="privacy-policy/"
                  >
                    {t("privacy_policy")}
                  </Link>
                  <Link
                    className="focus-visible:shadow-focus text-tertiary inline-block align-bottom underline transition-colors ease-out hover:text-neutral-700 focus-visible:outline-none"
                    data-ga-slug="Privacy Policy"
                    href="/terms-of-service"
                  >
                    {t("terms_of_service.termsOfService")}
                  </Link>
                  {/*<button className="focus-visible:shadow-focus text-tertiary inline-block align-bottom underline transition-colors ease-out hover:text-neutral-700 focus-visible:outline-none">*/}
                  {/*  Cookie Preference*/}
                  {/*</button>*/}
                </span>
              </p>
              {
                !isAbout &&
                  <div className="flex items-center gap-3">
                      <div className="flex h-full flex-row flex-wrap gap-x-3 gap-y-3">
                          <div>
                              <a
                                  className="focus-visible:shadow-focus app-download-link align-bottom transition-colors ease-out focus-visible:outline-none"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  data-ga-slug="download on app store"
                                href="https://apps.apple.com/az/app/esimafly/id6618155522"
                              >
                                  <div>
                                      <picture>
                                          <Image
                                              alt="apple store"
                                              loading="lazy"
                                              width="143"
                                              height="48"
                                              decoding="async"
                                              data-nimg="1"
                                              src="https://sb.nordcdn.com/m/3bd4c58600abc36b/original/apple-store.svg"
                                          />
                                      </picture>
                                  </div>
                              </a>
                          </div>
                          {/*<div>*/}
                          {/*    <a*/}
                          {/*        className="focus-visible:shadow-focus app-download-link align-bottom transition-colors ease-out focus-visible:outline-none"*/}
                          {/*        target="_blank"*/}
                          {/*        rel="noopener noreferrer"*/}
                          {/*        data-ga-slug="download on android"*/}
                          {/*      // href="/"*/}
                          {/*    >*/}
                          {/*        <div>*/}
                          {/*            <picture>*/}
                          {/*                <Image*/}
                          {/*                    alt="google play store"*/}
                          {/*                    loading="lazy"*/}
                          {/*                    width="143"*/}
                          {/*                    height="48"*/}
                          {/*                    decoding="async"*/}
                          {/*                    data-nimg="1"*/}
                          {/*                    src="https://sb.nordcdn.com/m/61c12f9617ed35b4/original/google-play-store.svg"*/}
                          {/*                />*/}
                          {/*            </picture>*/}
                          {/*        </div>*/}
                          {/*    </a>*/}
                          {/*</div>*/}
                      </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
