import Modal from "@/components/Modal/Modal";
import { useLocale, useTranslations } from "next-intl";
import { useRouter } from "@/navigation";
import { SessionPayload } from "@/app/[locale]/auth/definition";
import Cookies from "js-cookie";

interface ILogoutModalProps {
  setShowConfirmModal: (t: boolean) => void;
  showConfirmModal: boolean;
  setCurrentSession: (t: null | SessionPayload) => void;
}
const LogoutModal = ({
  setShowConfirmModal,
  showConfirmModal,
  setCurrentSession,
}) => {
  const t = useTranslations();
  const router = useRouter();
  const locale = useLocale();

  const approveLogout = async () => {
    try {
      Cookies.remove("session");
      setCurrentSession(null);
      setShowConfirmModal(false);
      window.location.href = `/${locale}`;
    } catch (e) {
      console.error("Error:", e);
    }
  };
  return (
    <Modal setShowModal={setShowConfirmModal} showModal={showConfirmModal}>
      <div className="flex flex-col gap-4 bg-white px-2 py-2">
        <div className="sm:flex sm:items-start">
          <div className="mt-2">
            <p className="text-xl">{t("confirmLogout")}</p>
          </div>
        </div>
        <div className="flex justify-end gap-2">
          <button
            type="button"
            onClick={approveLogout}
            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500  sm:w-auto"
          >
            {t("yes")}
          </button>
          <button
            type="button"
            onClick={() => setShowConfirmModal(false)}
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          >
            {t("cancel")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
