import { useState } from "react";
import menuData from "@/components/Header/menuData";
import Link from "next/link";
import { useParams, usePathname } from "next/navigation";
import { useTranslations } from "next-intl";
interface IHamburgerMenuProps {
  session: any;
}
export const HamburgerMenu = ({ session }: IHamburgerMenuProps) => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const pathName = usePathname();
  const params = useParams();
  const t = useTranslations();
  const navbarToggleHandler = () => {
    setNavbarOpen(!navbarOpen);
  };

  const handleLinkClick = () => {
    setNavbarOpen(false); // Close the navbar when a link is clicked
  };
  //check if userData is logged in

  return (
    <div>
      <button
        onClick={navbarToggleHandler}
        id="navbarToggler"
        aria-label="Mobile Menu"
        className="absolute right-4 top-1/2 block translate-y-[-50%] rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden"
      >
        <span
          className={`relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white ${
            navbarOpen ? " top-[7px] rotate-45" : " "
          }`}
        />
        <span
          className={`relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white ${
            navbarOpen ? "opacity-0 " : " "
          }`}
        />
        <span
          className={`relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white ${
            navbarOpen ? " top-[-8px] -rotate-45" : " "
          }`}
        />
      </button>
      <nav
        id="navbarCollapse"
        className={`navbar border-body-color/50 dark:border-body-color/20 dark:bg-dark absolute right-0 z-30 w-[200px] items-center rounded border-[.5px] bg-white px-6 py-4 duration-300 lg:visible lg:static lg:w-auto lg:border-none lg:!bg-transparent lg:p-0 lg:opacity-100 ${
          navbarOpen
            ? "visibility top-full opacity-100"
            : "invisible top-[120%] opacity-0"
        }`
      }
      >
        <ul className="block items-center font-medium lg:flex">
          {menuData.map((menu) => {
            let className: string;

            if (menu.primaryStyle) {
              className =
                "ease-in-up shadow-btn flex justify-center hover:shadow-btn-hover rounded-2xl bg-primary px-4 py-2 text-sm font-medium text-white transition duration-300 hover:bg-opacity-90 md:px-6 lg:px-6 xl:px-8";

            } else {
              className =
                "flex justify-center p-4 text-base lg:mr-0 lg:inline-flex text-dark hover:text-primary";
            }

            // This condition hides menu items based on session and menu.id
            const hidden = menu.id > 2 && session ? "hidden" : "";

            return (
              <li key={menu.id} className={`relative pt-1 ${hidden}`}>
                <Link
                  href={`/${params!.locale}${menu.path}`}
                  className={className}
                  onClick={handleLinkClick}
                >
                  {t(menu.title)}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};