import React, {Dispatch, SetStateAction, useEffect, useRef} from "react";
import Close from "@/icons/close";
interface modalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
}

export const Modal: React.FC<modalProps> = ({
  showModal,
  setShowModal,
  children,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showModal) {
      // Disable scrolling on the main page when the modal is open
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling on the main page when the modal is closed
      document.body.style.overflow = 'auto';
    }

    return () => {
      // Clean up by re-enabling scrolling if the component unmounts
      document.body.style.overflow = 'auto';
    };
  }, [showModal]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setShowModal(false);
      }
    };

    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal]);

  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div ref={modalRef} className="relative mx-auto my-6 w-auto max-w-3xl">
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white p-8 shadow-lg outline-none focus:outline-none ">
                <button
                  onClick={() => setShowModal(false)}
                  type="button"
                  className="absolute right-1 top-1 ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="default-modal"
                >
                  <Close />
                  <span className="sr-only">Close modal</span>
                </button>
                {children}
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
};
export default Modal;
