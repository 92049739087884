"use client";
import Image from "next/image";
import Link from "next/link";
import Cookies from "js-cookie";
import { useParams, usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import Modal from "@/components/Modal/Modal";
import { useLocale, useTranslations } from "next-intl";
import { LangToggle } from "@/components/LangToggle";
import { HamburgerMenu } from "@/components/Header/HamburgerMenu";
import axios from "axios";
import LogoutModal from "@/components/Modals/LogoutModal";
import { SessionPayload } from "@/app/[locale]/auth/definition";
import { EsimsIcon } from "@/icons/esimsIcon";
import {StoreIcon} from "@/icons/store";
interface HeaderProps {
  session: SessionPayload;
}
const Header = ({ session }: HeaderProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [sticky, setSticky] = useState(false);
  const t = useTranslations();
  const locale = useLocale();

  const [currentSession, setCurrentSession] = useState<SessionPayload | null>(
    session,
  );

  const handleLogout = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowConfirmModal(true);
  };

  // Sticky Navbar
  const handleStickyNavbar = () => {
    if (window.scrollY >= 80) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleStickyNavbar);
  });

  // submenu handler
  const [openIndex, setOpenIndex] = useState(-1);
  const handleSubmenu = (index) => {
    if (openIndex === index) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(index);
    }
  };
  const pathName = usePathname();

  return (
    <>
      <header
        className={`header left-0 top-0 z-40 flex h-[80px] w-full items-center ${
          sticky
            ? "shadow-sticky dark:bg-gray-dark dark:shadow-sticky-dark fixed z-[9999]  bg-white !bg-opacity-90 transition"
            : " bg-transparent"
        }`}
      >
        <div className="container">
          <div className="relative -mx-4 flex items-center justify-between">
            <div className="flex items-center ">
              <Link
                href={`/${locale}`}
                className={`header-logo block w-full  ${
                  sticky ? "py-10 lg:py-4" : ""
                } `}
              >
                <Image
                  src="/images/logo/logo5.svg"
                  alt="logo"
                  width={100}
                  height={25}
                  className="w-full"
                />
              </Link>

            </div>

            <div className="flex items-center ">
              <Link
                href={`/${locale}`}
                className={"hidden lg:flex flex border-l-2 gap-2 px-4 text-base font-medium lg:inline-flex whitespace-nowrap text-dark hover:text-primary"}
              >
                <StoreIcon />
                {t('packages')}
                {/*{t('esim_store')}*/}
              </Link>

              {/*<Link*/}
              {/*  href={`/${locale}/compatible-devices`}*/}
              {/*  className={`flex border-l-2 gap-2 px-4 text-base font-medium lg:inline-flex whitespace-nowrap text-dark hover:text-primary`*/}
              {/*}*/}
              {/*>*/}
              {/*  {t('compatible_devices')}*/}
              {/*</Link>*/}
            </div>

            <div className="flex w-full items-center justify-end px-4">
              <HamburgerMenu session={currentSession} />
              <div className="flex items-center justify-end pr-16 lg:pr-0  ">
                {currentSession ? (
                  <>
                    <Link
                      href="/my-esims"
                      className="hidden lg:flex flex items-center gap-2 px-4 pt-1 font-medium hover:text-primary"
                    >
                      <EsimsIcon color="#58C0E9" />
                      {t('my_esims')}
                    </Link>

                    <Link
                      href={"/dashboard"}
                      className="hidden lg:flex items-center pt-1 hover:text-primary"
                    >
                      <div className=" inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-gray-100 hover:text-primary">
                        <span className="font-medium text-gray-600 ">
                          {currentSession?.lastName ? currentSession?.firstName?.charAt(0) +
                            currentSession?.lastName?.charAt(0) : currentSession?.firstName?.charAt(0)  }
                        </span>
                      </div>
                      <span className="text-dark ml-2 text-base font-medium ">
                        {currentSession.firstName}
                      </span>
                    </Link>

                    <button
                      onClick={handleLogout}
                      className="text-dark px-7 pb-3 pt-4 text-base font-medium hover:text-primary "
                    >
                      {t("logout")}
                    </button>
                  </>
                ) : null}
                <LangToggle />
              </div>
            </div>
          </div>
        </div>
      </header>
      <LogoutModal
        setCurrentSession={setCurrentSession}
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
      />
    </>
  );
};

export default Header;
